import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import stateSelections from '../state/selections';

import FindQueryButton from './FindQueryButton';
import Loading from './Loading';
// import HighlightButton from './HighlightButton';

const { getFetchingSelectionsComplete } = stateSelections.selectors;

const Toolbar = () => {
  // selections
  const fetchingSelectionsComplete = useSelector(getFetchingSelectionsComplete);

  // Active worksheet change
  const [activeWorksheetId, setActiveWorksheetId] = useState('NA'); // eslint-disable-line
  const handleWorksheetChange = async () => {
    try {
      await Excel.run(async (context) => { // eslint-disable-line
        // console.log('Active worksheet changed');

        const sheet = context.workbook.worksheets.getActiveWorksheet(); // eslint-disable-line
        sheet.load('id');
        await context.sync();
        const worksheetId = sheet.id;

        setActiveWorksheetId(worksheetId);
      });
    } catch (error) {
      console.warn('handleWorksheetChange() error: ', error);
    }
  };

  // Register event handler
  useEffect(
    async () => {
      try {
        await Excel.run(async (context) => { // eslint-disable-line
          const sheets = context.workbook.worksheets;
          sheets.onActivated.add(handleWorksheetChange);
          handleWorksheetChange();

          return context.sync();
          /*
            // For Deebugging
            .then(() => {
              console.log('Event handler successfully reg`d for onChanged event in worksheets.');
            });
          */
        });
      } catch (error) {
        console.warn('ERROR', error);
      }
    },
    [],
  );

  // TODO: Find queries & codes (in selected area?)
  // higlight the queries & codes & promt if user wants to refresh
  // If yes, fetch the lates data & place in spreadsheet.
  // TODO: Find codes in existinf download & promt if user wants to update

  return (
    <>
      {!fetchingSelectionsComplete &&
        <Loading />}

      {fetchingSelectionsComplete &&
        <FindQueryButton
          activeWorksheetId={activeWorksheetId}
        />}

      {/*
      <HighlightButton />
      */}
    </>
  );
};

export default Toolbar;
