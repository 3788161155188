import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { AppContainer } from 'react-hot-loader'; // eslint-disable-line import/no-extraneous-dependencies

import './base.css';
import 'bootstrap/dist/css/bootstrap.css';

import bootstrap from 'bootstrap'; // eslint-disable-line

import configureStore from './store/configureStore';

import App from './components/App';

const store = configureStore();

const { Office } = window;

let isOfficeInitialized = false;

const renderApp = (Component) => {
  render(
    <AppContainer>
      <Provider store={store}>
        <Component isOfficeInitialized={isOfficeInitialized} />
      </Provider>
    </AppContainer>,
    document.getElementById('app-root'),
  );
};

Office.initialize = () => {
  isOfficeInitialized = true;
  renderApp(App);
};

renderApp(App);

if (module.hot) {
  module.hot.accept('./components/App', () => {
    const NextApp = require('./components/App').default; // eslint-disable-line global-require
    renderApp(NextApp);
  });
}
