import React from 'react';
import { useDispatch } from 'react-redux';

import stateApp from '../state/app';

import imgSrc from '../../media/fre-animation.gif';

const { updateFre } = stateApp.actions;

/*
 * For first time users we display the Value Placemat to state our value proposition.
 * See:
 * https://docs.microsoft.com/en-us/office/dev/add-ins/design/first-run-experience-patterns
 */
const ValuePlacemat = () => {
  const dispatch = useDispatch();

  const handleGetStarted = () => {
    dispatch(updateFre(true));
  };

  return (
    <div className="bs4 text-center">
      <h1>Welcome</h1>
      <p className="bs4 lead">
        The EasyData Excel Add-In allows you to update data downloaded from the EasyData service
        automatically to the latest up-to-date-data points.
      </p>
      <p>
        <img
          className="bs4 img-fluid"
          src={imgSrc}
          alt="Update data animation"
        />
      </p>
      <button
        className="bs4 btn btn-sm btn-primary mb-2"
        type="button"
        onClick={handleGetStarted}
      >
        Get started
      </button>
    </div>
  );
};

export default ValuePlacemat;
