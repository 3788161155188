import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  format,
  formatDistance,
} from 'date-fns';

import useFormInput from '../hooks/useFormInput';

import stateSelections from '../state/selections';

const { getSelections } = stateSelections.selectors;

const propTypes = {
  query: PropTypes.object.isRequired, // eslint-disable-line
  onSetQueryFrequency: PropTypes.func.isRequired,
  onSetQueryStartYear: PropTypes.func.isRequired,
  onRefreshQuery: PropTypes.func.isRequired,
};

const QueryCard = (props) => {
  // app
  const hostname = useSelector((state) => state.app.hostname);
  const authToken = useSelector((state) => state.app.authCredentials.token);

  // selections
  const selections = useSelector(getSelections);
  const untitledSelection = selections.find((obj) => obj.status === 'U');

  const {
    query,
    onSetQueryFrequency,
    onSetQueryStartYear,
    onRefreshQuery,
  } = props;

  const frequencyForm = useFormInput(query.newFrequency);
  const freqOptions = [
    {
      value: 'M',
      label: 'Monthly',
    },
    {
      value: 'Q',
      label: 'Quarterly',
    },
    {
      value: 'A',
      label: 'Annual',
    },
  ];
  useEffect(
    () => {
      onSetQueryFrequency(query.anchorAddress, frequencyForm.value);
    },
    [frequencyForm.value],
  );

  const startYearForm = useFormInput(query.startYear ? query.startYear.toString() : 'ALL');

  // Determine first year
  let firstYear;
  if (query.frequency && query.data) {
    // Data has been fetched and we know the exact start year for the entire query
    firstYear = query.data[query.frequency].global_start_year;
  } else if (query.startYear) {
    // Data has not been fetched, we do not know if the query start year is earlier than the year
    // we can derive from the sheet.
    firstYear = Number.parseInt(query.startYear, 10);
  }

  // Determine last year
  let lastYear;
  if (query.frequency && query.data) {
    lastYear = query.data[query.frequency].global_end_year;
  }

  // console.log('firstYear:', firstYear, 'lastYear:', lastYear);
  const availableYears = (firstYear && lastYear) ?
    [...Array((lastYear + 1) - firstYear).keys()].map((x) => (x + firstYear).toString()) :
    [];

  const startYearOptions = [
    {
      value: 'ALL',
      label: 'All available data',
    },
    ...availableYears.map((year) => ({
      value: year,
      label: year,
    })),
  ];
  useEffect(
    () => {
      let val;
      if (startYearForm.value !== 'ALL') {
        val = startYearForm.value;
      }
      onSetQueryStartYear(query.anchorAddress, val);
    },
    [startYearForm.value],
  );

  const displayLastUpdated = query.lastUpdated ?
    format(query.lastUpdated, 'yyyy-MM-dd @ HH:mm:ss') : 'Never';

  const distanceLastUpdated = query.lastUpdated ?
    formatDistance(
      query.lastUpdated,
      new Date(),
      { addSuffix: true },
    ) : false;

  const busy = query.step3 === 'BUSY' || query.step4 === 'BUSY' || query.step5 === 'BUSY';

  const [busyPreparingOnEasyData, setBusyPreparingOnEasyData] = useState(false);
  const handleViewOnEasyData = () => {
    setBusyPreparingOnEasyData(true);
    const url = `${hostname}/api/v3/selections/${untitledSelection.id}/add/?auth_token=${authToken}`;
    const body = JSON.stringify({
      timeseriescodes: query.tsCodes,
      empty: true,
    });
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body,
    })
      .then(() => {
        setBusyPreparingOnEasyData(false);
        const destUrl = `${hostname}/selections/${untitledSelection.id}/?new_selection=false`;
        // window.location.assign(`${hostname}/selections/316/`);
        // window.location.replace(destUrl);
        window.open(destUrl, 'EasyDataAddIn');
      });
  };

  return (
    <div>
      {query.warning ?
        <div>
          {`Warning: ${query.warning}`}
        </div> :
        <table className="bs5 table table-sm">
          <tbody>
            <tr>
              <td colSpan="2">
                <form>
                  <input
                    name="timeseriescodes"
                    type="hidden"
                    value={query.tsCodes}
                  />
                  <button
                    className="bs4 btn btn-sm btn-link p-0 shadow-none"
                    type="button"
                    disabled={busyPreparingOnEasyData}
                    onClick={handleViewOnEasyData}
                  >
                    {!busyPreparingOnEasyData ?
                      <span>Edit in EasyData</span> :
                      <span>Busy…</span>}
                  </button>
                </form>
              </td>
            </tr>
            <tr>
              <td>
                Last updated:
              </td>
              <td>
                <div className="bs4 mb-2">
                  <div>
                    {displayLastUpdated}
                  </div>
                  {distanceLastUpdated &&
                    <em className="bs4 text-small text-secondary">
                      {distanceLastUpdated}
                    </em>}
                </div>

                <div className="bs4 mb-2">
                  <button
                    className="bs5 btn btn-sm btn-primary shadow-none"
                    type="button"
                    onClick={onRefreshQuery}
                    disabled={busy}
                  >
                    Refresh data
                  </button>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                Transposed:
              </td>
              <td>
                {query.transposed ? 'Yes' : 'No'}
              </td>
            </tr>

            <tr>
              <td>
                Frequency:
              </td>
              <td>
                <select
                  value={frequencyForm.value}
                  className="bs4 form-control form-control-sm"
                  aria-label="Select frequency"
                  onChange={frequencyForm.onChange}
                >
                  {freqOptions.map((obj) =>
                    <option
                      value={obj.value}
                      key={obj.value}
                    >
                      {obj.label}
                    </option>)}
                </select>
              </td>
            </tr>

            <tr>
              <td>
                Start year:
              </td>
              <td>
                <select
                  value={startYearForm.value}
                  className="bs4 form-control form-control-sm"
                  aria-label="Select start year"
                  onChange={startYearForm.onChange}
                >
                  {startYearOptions.map((obj) =>
                    <option
                      value={obj.value}
                      key={obj.value}
                    >
                      {obj.label}
                    </option>)}
                </select>
              </td>
            </tr>

            <tr>
              <td>
                Time series count:
              </td>
              <td>
                {query.tsCodes.length}
              </td>
            </tr>

            <tr>
              <td>
                Selection title:
              </td>
              <td>
                {query.selectionTitle}
              </td>
            </tr>

            <tr>
              <td>
                Anchor address:
              </td>
              <td>
                <code>{query.anchorAddress}</code>
              </td>
            </tr>

            <tr>
              <td>
                TS range address:
              </td>
              <td>
                <code>{query.tsCodesAddress}</code>
              </td>
            </tr>
            {/*
            <tr>
              <td colSpan="2">
                <div>
                  <a href="/">
                    View on EasyData website
                  </a>
                </div>
                <div className="bs4 small text-muted">
                  {webURL}
                </div>
              </td>
            </tr>
            */}
          </tbody>
        </table>}
    </div>
  );
};

QueryCard.propTypes = propTypes;

export default QueryCard;
