import { differenceInCalendarMonths } from 'date-fns';

// Utility functions

export const dateToOA = (date) => {
  /*
   * An OLE Automation date is implemented as a floating-point number whose integral component is
   * the number of days before or after midnight, 30 December 1899, and whose fractional component
   * represents the time on that day divided by 24.
   * For example,
   * midnight, 30 December 1899 is represented by 0.0       30 Dec 1899 00:00:00 =  0.0
   * midnight, 31 December 1899 is represented by 1.0;      31 Dec 1899 00:00:00 =  1.0
   * 6 A.M., 1 January 1900 is represented by 2.25;          1 Jan 1900 06:00:00 =  2.25
   * midnight, 29 December 1899 is represented by -1.0;     29 Dec 1899 00:00:00 = -1.0
   * and 6 A.M., 29 December 1899 is represented by -1.25.  29 Dec 1899 06:00:00 = -1.25
   *
   * Example values. Use Date.UTC to ensure dates are at 00:00:00 UTC
   * midnight = Sat Dec 30 1899 01:30:00 GMT+0130 (South Africa Standard Time)
   * date = Sun Jan 31 2010 23:59:59 GMT+0200 (South Africa Standard Time)
   * dateMidnight = Sun Jan 31 2010 02:00:00 GMT+0200 (South Africa Standard Time)
   * oa = 40209 (NB, no decimals)
   * In Excel = 2010/01/31  (2010/01/31 00:00:00)
   *
   * Useful resource
   * https://stackoverflow.com/questions/7348805/what-is-equivalent-of-datetime-tooadate-in-javascript
   */
  const midnight = new Date(Date.UTC(1899, 11, 30));
  const dateMidnight = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  const oa = (dateMidnight - midnight) / (24 * 60 * 60 * 1000);
  return oa;
};

export const dateStringToOA = (dateString) => {
  /*
   * Convert a date stirn formatted like yyyy/mm/dd to an OA date.
   */
  const vals = dateString.split('/');
  const date = new Date(vals[0], vals[1] - 1, vals[2]);
  return dateToOA(date);
};

export const oaToDate = (oa) => {
  /*
   * Example values. Use Date.UTC to ensure dates are at 00:00:00 UTC
   * oa = 40209
   * date = Sun Jan 31 2010 02:00:00 GMT+0200 (South Africa Standard Time)
   */
  if (oa >= 1 && oa <= 55000) {
    const date =
      new Date((oa * (24 * 60 * 60 * 1000)) + (new Date(Date.UTC(1899, 11, 30))).getTime());
    return date;
  }
  return undefined;
};

/*
 * Given the first and second dates of a query in OLE Automation format, this function will
 * determine the frequency and return 'M', 'Q' or 'A'.
 */
export const getFrequency = (oaDateFirst, oaDateSecond) => {
  const firstDate = oaToDate(oaDateFirst);
  const secondDate = oaToDate(oaDateSecond);

  const months = differenceInCalendarMonths(secondDate, firstDate);
  let frequency = 'M';
  if (months === 3) {
    frequency = 'Q';
  } else if (months === 12) {
    frequency = 'A';
  }
  return frequency;
};
