import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { HOSTNAME } from '../constants';

import stateApp from '../state/app';
import stateSelections from '../state/selections';

import Header from './Header';
import Loading from './Loading';
import Login from './Login';
import Preferences from './Preferences';
import Selections from './Selections';
import Toolbar from './Toolbar';
import ValuePlacement from './ValuePlacemat';

const {
  updateHostname,
  updateAuthCredentials,
  updateFre,
  updateEnableFormatting,
} = stateApp.actions;
const {
  getIsAdmin,
  getLoggedInToday,
} = stateApp.selectors;

const { fetchSelections } = stateSelections.actions;

const propTypes = {
  isOfficeInitialized: PropTypes.bool.isRequired,
};

const App = (props) => {
  const dispatch = useDispatch();

  // app state
  const loggedInToday = useSelector(getLoggedInToday);
  const isAdmin = useSelector(getIsAdmin);
  const authToken = useSelector((state) => state.app.authCredentials.token);
  const fre = useSelector((state) => state.app.fre);

  const { isOfficeInitialized } = props;

  // Authentication
  const isAuthenticated = loggedInToday;

  useEffect(
    () => {
      const initialHostname = localStorage.getItem('hostname') || HOSTNAME;
      const initialFre = localStorage.getItem('fre') || false;
      // const initialEnableFormatting = localStorage.getItem('enableFormatting') === 'true' || true; // Initial value is true
      const storedValue = localStorage.getItem('enableFormatting');
      const initialEnableFormatting = storedValue !== null ? JSON.parse(storedValue) : true;

      dispatch(updateHostname(initialHostname));
      dispatch(updateFre(initialFre));
      dispatch(updateEnableFormatting(initialEnableFormatting));

      dispatch(updateAuthCredentials());
    },
    [],
  );

  // 'FRE', 'APP', 'SELECTIONS', 'PREFERENCES'
  const [view, setView] = useState('LOGIN');

  useEffect(
    () => {
      if (!isAuthenticated) {
        // Show first run experience?
        if (!fre) {
          setView('FRE');
        } else {
          setView('LOGIN');
        }
      } else {
        setView('APP');
        dispatch(fetchSelections({ auth_token: authToken }));
      }
    },
    [isAuthenticated, fre],
  );

  return (
    <div className="bs4 container-fluid h-100 d-flex flex-column">
      {!isOfficeInitialized &&
        <Loading />}

      {isOfficeInitialized &&
        <>
          <Header
            view={view}
            onSetView={setView}
          />

          <div>
            {view === 'FRE' &&
              <ValuePlacement />}

            {view === 'LOGIN' &&
              <Login />}

            {isAdmin && (view === 'APP' || view === 'SELECTIONS') &&
              <ul className="bs4 nav nav-tabs mb-2">
                <li className="nav-item">
                  <button
                    className={`bs4 nav-link mr-2 ${view === 'APP' ? ' active' : ''}`}
                    type="button"
                    onClick={() => setView('APP')}
                  >
                    Refresh
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`bs4 nav-link mr-2 ${view === 'SELECTIONS' ? ' active' : ''}`}
                    type="button"
                    onClick={() => setView('SELECTIONS')}
                  >
                    Selections
                  </button>
                </li>
              </ul>}
          </div>

          {view === 'APP' &&
            <Toolbar />}

          {view === 'SELECTIONS' &&
            <Selections />}

          {view === 'PREFERENCES' &&
            <Preferences />}
        </>}
    </div>
  );
};

App.propTypes = propTypes;

export default App;
