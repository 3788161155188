import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';

import rootReducer from '../rootReducer';

export default function configureStore() {
  const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);

  const store = createStoreWithMiddleware(rootReducer);

  return store;
}
