import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import QueryCard from './QueryCard';

const propTypes = {
  queries: PropTypes.arrayOf(PropTypes.object).isRequired, // eslint-disable-line
  onFetchQueryData: PropTypes.func.isRequired,
  onSetQueryFrequency: PropTypes.func.isRequired,
  onSetQueryStartYear: PropTypes.func.isRequired,
};

const QueryCards = (props) => {
  const {
    queries,
    onFetchQueryData,
    onSetQueryFrequency,
    onSetQueryStartYear,
  } = props;

  // Flip through queries
  const [queryIndex, setQueryIndex] = useState(-1);
  const query = queries[queryIndex];
  const queryCount = queries.length;
  function handleNextQuery() {
    const nextIndex = queryIndex + 1 === queries.length ? 0 : queryIndex + 1;
    setQueryIndex(nextIndex);
  }
  function handlePrevQuery() {
    const nextIndex = queryIndex === 0 ? queries.length - 1 : queryIndex - 1;
    setQueryIndex(nextIndex);
  }

  const selectQuery = async (anchorAddress) => {
    try {
      await Excel.run(async (context) => { // eslint-disable-line
        console.log('Function: selectQuery()');
        const sheet = context.workbook.worksheets.getActiveWorksheet();
        const anchorRange = sheet.getRange(anchorAddress);
        let completeQueryRange = query.transposed ?
          anchorRange.getResizedRange(query.tsCodes.length, -6) :
          anchorRange.getResizedRange(-6, query.tsCodes.length);
        completeQueryRange = query.transposed ?
          completeQueryRange.getResizedRange(0, query.dataRowCount) :
          completeQueryRange.getResizedRange(query.dataRowCount, 0);
        completeQueryRange.select();
        await context.sync();
      });
    } catch (error) {
      console.warn(error);
    }
  };

  const handleRefreshQuery = () => {
    onFetchQueryData(query.anchorAddress);
  };

  useEffect(
    () => {
      if (queryCount > 0) {
        setQueryIndex(0);
      }
    },
    [queryCount],
  );

  useEffect(
    () => {
      if (queryIndex > -1) {
        selectQuery(query.anchorAddress);
      }
    },
    [queryIndex],
  );

  const queryReady = queryIndex >= 0 && query.step2 === 'DONE';

  return (
    <div>
      {queryReady &&
        <>
          <div className="bs5 d-flex mb-3">
            {queryCount > 1 &&
              <button
                className="bs5 btn btn-sm btn-outline-secondary shadow-none"
                type="button"
                onClick={handlePrevQuery}
              >
                « Previous
              </button>}
            <span className="bs5 flex-fill text-center mx-3">
              <strong>{`Data query ${queryIndex + 1}`}</strong>
            </span>
            {queryCount > 1 &&
              <button
                className="bs5 btn btn-sm btn-outline-secondary shadow-none"
                type="button"
                onClick={handleNextQuery}
              >
                Next »
              </button>}
          </div>

          <QueryCard
            query={query}
            onSetQueryFrequency={onSetQueryFrequency}
            onSetQueryStartYear={onSetQueryStartYear}
            onRefreshQuery={handleRefreshQuery}
            key={query.anchorAddress}
          />
        </>}

      {queryIndex === -1 &&
        <p className="bs5 text-secondary m-5 text-center">
          No data queries found in worksheet.
        </p>}
    </div>
  );
};

QueryCards.propTypes = propTypes;

export default QueryCards;
