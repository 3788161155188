import { MULTIPLIERS } from './constants';

export const initialState = {
  findQueries: false, // false, true or Date.now()
  worksheetId: false,
  message: undefined,
  queryUpdateQueue: [],
  queries: [],
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'RESET':
      return initialState;
    case 'SET_MESSAGE':
      return {
        ...initialState,
        message: action.message,
      };
    case 'START':
      // console.log('START');
      return {
        ...initialState,
        findQueries: true,
      };
    case 'INIT': {
      // console.log('INIT');

      // Remove any -NNN occurences from ts codes
      const tsCodes = action.tsCodes.map((tsCode) => {
        const re = /-NNN$/;
        return tsCode.replace(re, '');
      });
      return {
        findQueries: Date.now(),
        worksheetId: action.worksheetId,
        queryUpdateQueue: [],
        queries: [
          ...state.queries,
          {
            anchorAddress: action.anchorAddress,
            tsCodesAddress: action.tsCodesAddress, // Sheet1!C1:D1
            tsCodes, // ['ABS-ABSAHPI', 'NMS-TOT_PAS']
            warning: action.warning,
            transposed: action.transposed,
            frequency: action.frequency,
            step1: 'DONE', // Find anchor:   DONE
            step2: 'BUSY', // Isolate codes: TODO, BUSY, DONE
            step3: 'TODO', // Fetch data:    TODO, BUSY, DONE, ERROR
            step4: 'TODO', // Store data:    TODO, BUSY, DONE, ERROR
            step5: 'TODO', // Update data:   TODO, BUSY, DONE, ERROR
          },
        ],
      };
    }
    case 'INSERT_DATES_AND_META': {
      const idx = state.queries
        .findIndex((obj) => obj.anchorAddress === action.anchorAddress);
      return {
        ...state,
        queries: [
          ...state.queries.slice(0, idx),
          {
            ...state.queries[idx],
            datesRangeAddress: action.datesRangeAddress,
            dataRangeAddress: action.dataRangeAddress,
            startYear: action.startYear, // 1980
            newStartYear: action.startYear, // 1980
            dataRowCount: action.dataRowCount, // 100
            metaAddress: action.metaAddress, // Sheet1!B2:C7
            meta: action.meta, // {}
            frequency: action.frequency,
            newFrequency: action.frequency,
            selectionTitle: action.selectionTitle,
            lastUpdated: action.lastUpdated,
            step2: 'DONE',
          },
          ...state.queries.slice(idx + 1),
        ],
      };
    }
    case 'INSERT_WARNING': {
      const idx = state.queries
        .findIndex((obj) => obj.anchorAddress === action.anchorAddress);
      return {
        ...state,
        queries: [
          ...state.queries.slice(0, idx),
          {
            ...state.queries[idx],
            warning: action.warning,
            step2: 'DONE',
            step3: 'DONE',
            step4: 'DONE',
            step5: 'DONE',
          },
          ...state.queries.slice(idx + 1),
        ],
      };
    }
    case 'EDIT_FREQUENCY': {
      const idx = state.queries
        .findIndex((obj) => obj.anchorAddress === action.anchorAddress);
      return {
        ...state,
        queries: [
          ...state.queries.slice(0, idx),
          {
            ...state.queries[idx],
            newFrequency: action.newFrequency,
          },
          ...state.queries.slice(idx + 1),
        ],
      };
    }
    case 'EDIT_STARTYEAR': {
      const idx = state.queries
        .findIndex((obj) => obj.anchorAddress === action.anchorAddress);
      return {
        ...state,
        queries: [
          ...state.queries.slice(0, idx),
          {
            ...state.queries[idx],
            newStartYear: Number.parseInt(action.newStartYear, 10),
          },
          ...state.queries.slice(idx + 1),
        ],
      };
    }
    case 'EDIT_LASTUPDATED': {
      const idx = state.queries
        .findIndex((obj) => obj.anchorAddress === action.anchorAddress);
      return {
        ...state,
        queries: [
          ...state.queries.slice(0, idx),
          {
            ...state.queries[idx],
            lastUpdated: action.newLastUpdated,
          },
          ...state.queries.slice(idx + 1),
        ],
      };
    }
    case 'FETCH_DATA': {
      const idx = state.queries
        .findIndex((obj) => obj.anchorAddress === action.anchorAddress);
      return {
        ...state,
        queries: [
          ...state.queries.slice(0, idx),
          {
            ...state.queries[idx],
            step3: action.status,
            step4: 'TODO',
            step5: 'TODO',
          },
          ...state.queries.slice(idx + 1),
        ],
      };
    }
    case 'SKIP_FETCH_DATA': {
      const idx = state.queries
        .findIndex((obj) => obj.anchorAddress === action.anchorAddress);
      return {
        ...state,
        queries: [
          ...state.queries.slice(0, idx),
          {
            ...state.queries[idx],
            step3: 'DONE',
            step4: 'DONE',
            step5: 'DONE',
          },
          ...state.queries.slice(idx + 1),
        ],
      };
    }
    case 'INSERT_DATA': {
      // If sheet has changed, do not update
      if (action.workSheetId === state.worksheetId) {
        const idx = state.queries
          .findIndex((obj) => obj.anchorAddress === action.anchorAddress);

        const { newFrequency } = state.queries[idx];
        const globalStartYear = action.data[newFrequency].global_start_year;
        const globalStartMonth = action.data[newFrequency].global_start_month;

        const DIVS = {
          M: 1,
          Q: 3,
          A: 12,
        };
        const startYear = state.queries[idx].newStartYear || globalStartYear;
        const startMonth = startYear !== globalStartYear ? 1 : globalStartMonth;

        const sub = Math.ceil(globalStartMonth / DIVS[newFrequency]) - 1;
        const startIdx = startYear > globalStartYear ?
          (startYear - globalStartYear) * MULTIPLIERS[newFrequency] - sub : 0;

        const valuesFirstTimeSeries = action.data[newFrequency].ts_li[0].values;
        let newDataRowCount = valuesFirstTimeSeries.split(',').slice(startIdx).length;

        const padYears = globalStartYear - state.queries[idx].newStartYear;

        let padValues = [];
        if (padYears > 0) {
          // We need to pad the data with some years worth of blank data
          newDataRowCount += padYears * MULTIPLIERS[newFrequency];
          padValues = new Array(padYears * MULTIPLIERS[newFrequency]).fill('NA');

          // We also need to pad the data with some months & quarters of blank data
          // e.g. Monthly and globalStartMonth = 6 then pad with 5 NA's
          //    Quarterly and globalStartMonth = 6 = then pad with 1 NA
          let padMonths = [];
          if (newFrequency === 'M') {
            padMonths = new Array(globalStartMonth - 1).fill('NA');
            padValues = [
              ...padValues,
              ...padMonths,
            ];
          }

          let padQuarters = [];
          if (newFrequency === 'Q') {
            padQuarters = new Array((globalStartMonth / 3) - 1).fill('NA');
            padValues = [
              ...padValues,
              ...padQuarters,
            ];
          }
        }

        return {
          ...state,
          queryUpdateQueue: [
            ...state.queryUpdateQueue,
            action.anchorAddress,
          ],
          queries: [
            ...state.queries.slice(0, idx),
            {
              ...state.queries[idx],
              data: action.data,
              padValues,
              // startYear: state.queries[idx].newStartYear,
              startYear,
              startMonth,
              startIdx,
              frequency: state.queries[idx].newFrequency,
              newDataRowCount,
              step3: 'DONE',
              step4: 'DONE',
            },
            ...state.queries.slice(idx + 1),
          ],
        };
      }
      return state;
    }
    case 'UPDATE_DATA': {
      const idx = state.queries
        .findIndex((obj) => obj.anchorAddress === action.anchorAddress);
      return {
        ...state,
        queries: [
          ...state.queries.slice(0, idx),
          {
            ...state.queries[idx],
            dataRowCount: state.queries[idx].newDataRowCount,
            step5: action.status,
          },
          ...state.queries.slice(idx + 1),
        ],
      };
    }
    case 'REMOVE_FROMQUEUE':
      return {
        ...state,
        queryUpdateQueue: state.queryUpdateQueue.slice(1),
      };
    default:
      throw new Error();
  }
};
