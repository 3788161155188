import React, { useMemo, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  endOfMonth,
  addMonths,
  addQuarters,
  endOfQuarter,
  addYears,
  endOfYear,
  format,
  parse,
} from 'date-fns';

import {
  initialState,
  reducer,
} from '../reducer';

import StatusBar from './StatusBar';
import QueryCards from './QueryCards';

import {
  oaToDate,
  dateToOA,
  dateStringToOA,
  getFrequency,
} from '../utils';

import {
  BLUE,
  BLUE_LIGHT,
  GREEN,
  GREEN_LIGHT,
  RED,
  RED_LIGHT,
  GREY,
  GREY_LIGHT,
  MONTHLY,
  QUARTERLY,
  ANNUAL,
} from '../constants';

const propTypes = {
  activeWorksheetId: PropTypes.string.isRequired,
};

const FindQueryButton = (props) => {
  // app
  const hostname = useSelector((state) => state.app.hostname);
  const authToken = useSelector((state) => state.app.authCredentials.token);
  const enableFormatting = useSelector((state) => state.app.enableFormatting);

  const { activeWorksheetId } = props;

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(
    () => {
      // Switched worksheets
      // console.log(activeWorksheetId);
      dispatch({ type: 'RESET' });
    },
    [activeWorksheetId],
  );

  const queryCount = state.queries.length;
  const faultyQueryCount = state.queries.filter((obj) => obj.warning).length;
  const goodQueryCount = queryCount - faultyQueryCount;

  const frequencyTitles = {
    M: 'Monthly',
    Q: 'Quarterly',
    A: 'Annual',
  };

  const step1Arr = state.queries.map((obj) => obj.step1);
  const step2Arr = state.queries.map((obj) => obj.step2);
  const step3Arr = state.queries.map((obj) => obj.step3);
  const step4Arr = state.queries.map((obj) => obj.step4);
  const step5Arr = state.queries.map((obj) => obj.step5);

  const step1Status = useMemo(
    () => {
      // console.log('Derive step1Status');
      if (state.findQueries === false) {
        return 'TODO';
      }
      if (state.findQueries === true) {
        return 'BUSY';
      }
      return 'DONE';
    },
    [state.findQueries, step1Arr],
  );

  const step2Status = useMemo(
    () => {
      // console.log('Derive step2Status');
      if (step1Status !== 'DONE') {
        return 'TODO';
      }
      if (step2Arr.findIndex((x) => x === 'TODO') > -1) {
        return 'TODO';
      }
      if (step2Arr.findIndex((x) => x === 'BUSY') > -1) {
        return 'BUSY';
      }
      return 'DONE';
    },
    [step1Status, step2Arr],
  );

  const step3Status = useMemo(
    () => {
      // console.log('Derive step3Status');
      if (step2Status !== 'DONE') {
        return 'TODO';
      }
      if (queryCount === 0) {
        return 'TODO';
      }
      if (step3Arr.findIndex((x) => x === 'BUSY') > -1) {
        return 'BUSY';
      }
      if (step3Arr.findIndex((x) => x === 'TODO') > -1) {
        return 'TODO';
      }
      return 'DONE';
    },
    [step2Status, step3Arr],
  );

  const step4Status = useMemo(
    () => {
      // console.log('Derive step4Status');
      if (step3Status !== 'DONE') {
        return 'TODO';
      }
      if (step4Arr.findIndex((x) => x === 'BUSY') > -1) {
        return 'BUSY';
      }
      if (step4Arr.findIndex((x) => x === 'TODO') > -1) {
        return 'TODO';
      }
      return 'DONE';
    },
    [step3Status, step4Arr],
  );

  const step5Status = useMemo(
    () => {
      // console.log('Derive step5Status');
      if (step4Status !== 'DONE') {
        return 'TODO';
      }
      if (step5Arr.findIndex((x) => x === 'BUSY') > -1) {
        return 'BUSY';
      }
      if (step5Arr.findIndex((x) => x === 'TODO') > -1) {
        return 'TODO';
      }
      return 'DONE';
    },
    [step4Status, step5Arr],
  );

  // Find the query start year and meta
  const findQueryDatesAndMeta = async (anchorAddress, tsCodesCount, transposed, foundFrequency) => {
    try {
      await Excel.run(async (context) => { // eslint-disable-line
        console.log('Function: findQueryDatesAndMeta()');
        const sheet = context.workbook.worksheets.getActiveWorksheet();
        const queryAnchorRange = sheet.getRange(anchorAddress);

        // 2. Find start date and row count
        let dateRange = transposed ?
          queryAnchorRange.getOffsetRange(0, 1) : queryAnchorRange.getOffsetRange(1, 0);
        dateRange = transposed ? dateRange.getResizedRange(0, 1) : dateRange.getResizedRange(1, 0);
        dateRange.load('values');
        await context.sync();

        let oaDate = dateRange.values[0][0]; // First date
        // Second date, now we can determine frequency
        let oaDate2 = transposed ? dateRange.values[0][1] : dateRange.values[1][0];

        if (typeof oaDate === 'string') {
          oaDate = dateStringToOA(oaDate);
        }
        if (typeof oaDate2 === 'string') {
          oaDate2 = dateStringToOA(oaDate2);
        }

        // Determine row count
        const frequencyFromDates = getFrequency(oaDate, oaDate2);
        let startYear;
        let dataRowCount = 0;

        try {
          startYear = oaDate ? oaToDate(oaDate).getFullYear() : undefined;

          // A 100 years worth of months 12*100=1200
          dateRange = transposed ?
            dateRange.getResizedRange(0, 1200) : dateRange.getResizedRange(1200, 0);
          dateRange.load('valueTypes');
          dateRange.load('values');
          await context.sync();

          const vTypes = transposed ?
            dateRange.valueTypes[0]
              .map((_, colIdx) => dateRange.valueTypes.map((row) => row[colIdx])) :
            dateRange.valueTypes;
          const values = transposed ?
            dateRange.values[0]
              .map((_, colIdx) => dateRange.values.map((row) => row[colIdx])) :
            dateRange.values;
          await context.sync();

          let prevVal = 0;
          values.some((val, idx) => {
            const vType = vTypes[idx][0];
            const isDouble = vType === Excel.RangeValueType.double;  // eslint-disable-line
            const isBigger = isDouble && (val > prevVal);
            // console.log('hey', val, prevVal, vType, isDouble, isBigger);
            const isOK = isDouble && isBigger;
            dataRowCount = isOK ? dataRowCount + 1 : dataRowCount;
            prevVal = val;

            // const isEmpty = vType === Excel.RangeValueType.empty; // eslint-disable-line
            // dataRowCount = !isEmpty ? dataRowCount + 1 : dataRowCount;
            return !isOK;
          });

          await context.sync();
        } catch (error) {
          console.warn('FIND dates error: ', error);
          dataRowCount = 0;
        }

        console.log('rowCount:', dataRowCount);

        // Find the dates range background
        let datesRange = transposed ?
          queryAnchorRange.getOffsetRange(0, 1) : queryAnchorRange.getOffsetRange(1, 0);
        if (dataRowCount > 0) {
          datesRange = transposed ?
            datesRange.getResizedRange(0, dataRowCount - 1) :
            datesRange.getResizedRange(dataRowCount - 1, 0); // eslint-disable-line
        }

        // 3. Find meta
        let metaRange;
        try {
          metaRange = transposed ?
            queryAnchorRange.getOffsetRange(0, -6) : queryAnchorRange.getOffsetRange(-6, 0);
          metaRange = transposed ?
            metaRange.getResizedRange(tsCodesCount, 5) : metaRange.getResizedRange(5, tsCodesCount);
          // FORMATTING
          if (enableFormatting) {
            metaRange.format.fill.color = GREEN_LIGHT;
          }
          metaRange.load('address');
          metaRange.load('values');
          await context.sync();
        } catch (err) {
          // The resized range is forced outside the bounds of the worksheet grid.
          const warning = transposed ?
            'Query needs more space. Ensure at least 6 empty columns left of codes' :
            'Query needs more space. Ensure at least 6 empty rows above codes';
          dispatch({
            type: 'INSERT_WARNING',
            anchorAddress,
            warning,
          });
        }
        const metaAddress = metaRange.address;

        const meta = {
          frequency: '',
          notes: '',
          unit: '',
          source: '',
          description: '',
          title: '',
        };

        let selectionTitle;
        let lastUpdated;

        const metaValues = transposed ?
          metaRange.values[0].map((_, colIndex) => metaRange.values.map((row) => row[colIndex])) :
          metaRange.values;

        metaValues.forEach((obj) => {
          if (obj[0].toUpperCase() === 'FREQUENCY') {
            meta.frequency = obj.slice(1);
          } else if (obj[0].toUpperCase() === 'UNIT') {
            meta.unit = obj.slice(1);
          } else if (obj[0].toUpperCase() === 'NOTES') {
            meta.notes = obj.slice(1);
          } else if (obj[0].toUpperCase() === 'SOURCE') {
            meta.source = obj.slice(1);
          } else if (obj[0].toUpperCase() === 'DESCRIPTION') {
            meta.description = obj.slice(1);
          // } else if (obj[0].toUpperCase() === 'TITLE') {
          } else {
            try {
              const re1 = /,([A-Za-z0-9 ]+)\(/g;
              const matches1 = obj[0].match(re1);
              [selectionTitle] = matches1;
              selectionTitle = selectionTitle.slice(2, -2);
            } catch (err) {
              selectionTitle = 'Untitled';
              // console.warn('Selection title parse error: ', err);
            }

            try {
              const re2 = /[0-9]{4}-[0-9]{2}-[0-9]{2} at [0-9]{2}:[0-9]{2}:[0-9]{2}/g;
              const matches2 = obj[0].match(re2);
              [lastUpdated] = matches2;
              lastUpdated = parse(lastUpdated, "yyyy-MM-dd 'at' HH:mm:ss", new Date());
            } catch (err) {
              // console.warn('Last updated parse error: ', err);
            }
            meta.title = obj.slice(1);
          }
        });

        // If frequency not specified in keyword, we use frequency derived from dates
        // Useful on downloads or legacy queries.
        let frequency = 'A';
        if (foundFrequency) {
          frequency = foundFrequency;
        } else if (meta.frequency[0]) {
          // eslint-disable-next-line prefer-destructuring
          frequency = meta.frequency[0][0];
        } else {
          frequency = frequencyFromDates;
        }
        // const frequency = meta.frequency[0] ? meta.frequency[0][0] : frequencyFromDates;

        // Store data and dates ranges in state
        let dataRange = queryAnchorRange.getOffsetRange(1, 1);
        if (dataRowCount > 0) {
          dataRange = dataRange.getResizedRange(dataRowCount - 1, 0); // eslint-disable-line
        }
        if (tsCodesCount > 0) {
          dataRange = dataRange.getResizedRange(0, tsCodesCount - 1);
        }
        dataRange.load('address');
        datesRange.load('address');
        await context.sync();

        const datesRangeAddress = datesRange.address;
        const dataRangeAddress = dataRange.address;

        dispatch({
          type: 'INSERT_DATES_AND_META',
          anchorAddress,
          datesRangeAddress,
          dataRangeAddress,
          startYear,
          dataRowCount,
          metaAddress,
          meta,
          frequency,
          selectionTitle,
          lastUpdated,
        });
      });
    } catch (error) {
      console.warn(`findQueryDatesAndMeta() error: ${error}`);
    }
  };

  const testValidTSCode = (val) => {
    const regexTSCode = /^([A-Z0-9_]+-[A-Z0-9_-]+)(-NN[PAY]{1})?$|^([A-Z0-9_]+-[A-Z0-9_]+)(-[SA]{1}[QA]{1}[NPAY]{1})?$'/;
    return regexTSCode.test(val);
  };

  // Validate that a found query keyword is valid
  // Highlight the query keyword cell
  // If valid, call function to find query codes
  const validateQueryKeyword = async (anchorAddress) => {
    try {
      await Excel.run(async (context) => { // eslint-disable-line
        console.log('Function: validateQuery()');
        const sheet = context.workbook.worksheets.getActiveWorksheet();
        const queryAnchorRange = sheet.getRange(anchorAddress);

        sheet.load('id');
        queryAnchorRange.load('values');
        await context.sync();

        const worksheetId = sheet.id;

        // 1. Validate keyword
        let validKeyword = false;
        const keyword = queryAnchorRange.values[0][0];
        // const regexKeyword = new RegExp('CODES:[MQA]');
        const regexKeyword = /CODES:[MQA]/;
        let frequency;
        if (keyword.toLowerCase() === 'code') {
          // Legacy
          validKeyword = true;
        } else if (regexKeyword.test(keyword)) {
          // Add-In 2.0
          // eslint-disable-next-line prefer-destructuring
          frequency = keyword.split(':')[1];
          validKeyword = true;
        }

        // 2. Determine orientation
        const anchorCellRight = queryAnchorRange.getOffsetRange(0, 1);
        anchorCellRight.load('values');
        anchorCellRight.load('valueTypes');
        const anchorCellDown = queryAnchorRange.getOffsetRange(1, 0);
        anchorCellDown.load('values');
        anchorCellDown.load('valueTypes');
        await context.sync();

        const rightValue = anchorCellRight.values[0][0];
        const rightValueType = anchorCellRight.valueTypes[0][0];
        const rightIsStr =
          rightValueType === Excel.RangeValueType.string; // eslint-disable-line no-undef

        let transposed = false;
        let checkTransposed = true;
        if (rightIsStr) {
          if (testValidTSCode(rightValue)) {
            checkTransposed = false;
          }
        }

        const downValue = anchorCellDown.values[0][0];
        const downValueType = anchorCellDown.valueTypes[0][0];
        const downIsStr =
          downValueType === Excel.RangeValueType.string; // eslint-disable-line no-undef

        if (checkTransposed && downIsStr) {
          if (testValidTSCode(downValue)) {
            transposed = true;
          }
        }

        // 3. Find and validate time series codes
        let tsCodesValid = false;
        const tsCountAllowed = 199; // 199 => 200 (0 indexed)
        let tsCodesRangeToSearch = transposed ?
          queryAnchorRange.getOffsetRange(1, 0) : queryAnchorRange.getOffsetRange(0, 1);
        tsCodesRangeToSearch = transposed ?
          tsCodesRangeToSearch.getResizedRange(tsCountAllowed, 0) :
          tsCodesRangeToSearch.getResizedRange(0, tsCountAllowed);
        tsCodesRangeToSearch.load('values');
        tsCodesRangeToSearch.load('valueTypes');
        await context.sync();

        let { values, valueTypes } = tsCodesRangeToSearch;
        values = transposed ? values.map((v) => v[0]) : [...values[0]];
        valueTypes = transposed ? valueTypes.map((v) => v[0]) : [...valueTypes[0]];

        let tsCodeCount = 0;
        valueTypes.some((x, i) => {
          const val = values[i];
          const isEmpty = x === Excel.RangeValueType.empty; // eslint-disable-line no-undef
          let isValidTSCode = false;
          if (!isEmpty) {
            // Cell not empty, test if content is a valid ts code
            // RegExp testing site https://regexr.com/5v6bj
            isValidTSCode = testValidTSCode(val);
          }

          if (validKeyword && isValidTSCode) {
            // Only color cells if keyword and ts codes are valid
            tsCodeCount = isValidTSCode ? tsCodeCount + 1 : tsCodeCount;
            // FORMATTING
            if (enableFormatting) {
              // Color valid cells green
              const cellRange = transposed ?
                queryAnchorRange.getOffsetRange(tsCodeCount, 0) :
                queryAnchorRange.getOffsetRange(0, tsCodeCount);
              cellRange.format.fill.color = GREEN_LIGHT;
              cellRange.format.font.color = GREEN;
              cellRange.format.font.bold = true;
            }
          }

          const stopLookingForTSCodes = !isValidTSCode;
          return stopLookingForTSCodes;
        });
        tsCodesValid = tsCodeCount > 0;

        let tsCodesRange = transposed ?
          queryAnchorRange.getOffsetRange(1, 0) : queryAnchorRange.getOffsetRange(0, 1);
        if (tsCodesValid) {
          tsCodesRange = transposed ?
            tsCodesRange.getResizedRange(tsCodeCount - 1, 0) :
            tsCodesRange.getResizedRange(0, tsCodeCount - 1);
          tsCodesRange.load('address');
          tsCodesRange.load('values');
        }
        await context.sync();

        let tsCodesAddress = '';
        let tsCodes = [];
        if (tsCodesValid) {
          tsCodesAddress = tsCodesRange.address;
          tsCodes = transposed ? tsCodesRange.values.map((v) => v[0]) : [...tsCodesRange.values[0]];
        }

        if (validKeyword) {
          // FORMATTING
          if (enableFormatting) {
            queryAnchorRange.format.fill.color = tsCodesValid ? GREEN_LIGHT : RED_LIGHT;
            queryAnchorRange.format.font.color = tsCodesValid ? GREEN : RED;
            queryAnchorRange.format.font.bold = true;
          }
          dispatch({
            type: 'INIT',
            worksheetId,
            anchorAddress,
            tsCodesAddress,
            tsCodes,
            frequency,
            warning: tsCodesValid ? '' : 'Invalid time series codes, please review and try again.',
            transposed,
          });
          findQueryDatesAndMeta(anchorAddress, tsCodes.length, transposed, frequency);
        } else {
          throw new Error('No valied query keywords found on this sheet. e.g. CODES:M');
        }
      });
    } catch (error) {
      console.warn(`validateQueryKeyword() error: ${error}`);
      dispatch({
        type: 'SET_MESSAGE',
        message: error.message,
      });
    }
  };

  const handleFindQueries = async () => {
    try {
      await Excel.run(async (context) => { // eslint-disable-line
        console.log('Function: handleFindQueries()');
        dispatch({ type: 'RESET' });
        dispatch({ type: 'START' });

        const sheet = context.workbook.worksheets.getActiveWorksheet();
        sheet.load('id');
        await context.sync();

        // If no cells meet this criteria, an ItemNotFound error will be thrown.
        // New keyword: CODES:[M,Q,A]:(YYYY):(YYYY)
        // Legacy Add-In keyword: Code
        // Legacy download keyword: code
        const foundRanges1 = sheet.findAllOrNullObject('code', {
          completeMatch: true,
          matchCase: false,
        }); // e.g. 'Sheet1!B1,Sheet1!G1,Sheet1!M1'

        const foundRanges2 = sheet.findAllOrNullObject('CODES:', {
          completeMatch: false,
          matchCase: true,
        });
        await context.sync();

        if (foundRanges1.isNullObject && foundRanges2.isNullObject) {
          throw new Error('No data queries found on this sheet.');
        }

        foundRanges1.load('address');
        foundRanges2.load('address');
        await context.sync();

        let anchorAddresses = foundRanges1.address ? [...foundRanges1.address.split(',')] : [];
        anchorAddresses = foundRanges2.address ? [
          ...anchorAddresses,
          ...foundRanges2.address.split(','),
        ] : [...anchorAddresses];

        anchorAddresses.forEach((addr) => {
          validateQueryKeyword(addr);
        });
      });
    } catch (error) {
      // https://docs.microsoft.com/en-us/office/dev/add-ins/excel/excel-add-ins-error-handling#api-errors
      const errorMessages = {
        ItemNotFound: 'No EasyData queries found on this sheet.',
        InvalidOperationInCellEditMode: error.message,
      };
      dispatch({ type: 'RESET' });
      dispatch({
        type: 'SET_MESSAGE',
        message: errorMessages[error.code] || error.message,
      });
      // console.warn(`handleFindQueries() error: ${error.message}`);
    }
  };

  const handleFetchQueryData = (anchorAddress) => {
    console.log('handleFetchQueryData() -> ', anchorAddress);
    const query = state.queries.find((obj) => obj.anchorAddress === anchorAddress);

    dispatch({
      type: 'FETCH_DATA',
      anchorAddress,
      status: 'BUSY',
    });
    const tsCodeStr = query.tsCodes.join(',');

    const tsURL = `${hostname}/api/v3/download/`;
    let url = `${tsURL}?respFormat=json&freqs=${query.newFrequency}&timeSeriesCodes=${tsCodeStr}&auth_token=${authToken}`;
    if (query.newStartYear) {
      url = `${url}&startYear=${query.newStartYear}`;
    }

    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      // mode: 'no-cors',
    })
      .then((resp) => {
        console.log(`-- fetchQueryData(): ${resp.status}`);
        return resp;
      })
      .then((resp) => {
        if (resp.status >= 200 && resp.status < 300) {
          return resp;
        }

        const error = new Error(resp.statusText);
        error.response = resp;
        throw error;
      })
      .then((resp) => resp.json())
      .then(
        (json) => {
          dispatch({
            type: 'INSERT_DATA',
            workSheetId: activeWorksheetId,
            anchorAddress,
            data: json,
          });
        },
        (error) => {
          console.warn(`-- fetchQueryData(): ${error}`);
          /*
          dispatch({
            type: 'FETCH_DATA',
            anchorAddress,
            status: 'ERROR',
          });
          */
          dispatch({
            type: 'INSERT_WARNING',
            anchorAddress,
            warning: 'Something went wrong, please contact support.',
          });
        },
      );
  };

  // Do not try to fetch data for malformed queries
  const handleSkipFetch = (anchorAddress) => {
    console.log('handleSkipFetch() -> ', anchorAddress);
    dispatch({
      type: 'SKIP_FETCH_DATA',
      anchorAddress,
    });
  };

  const handleFetchAllData = () => {
    console.log('handleFetchData()');
    state.queries.forEach((query) => {
      if (query.warning === '') {
        handleFetchQueryData(query.anchorAddress);
      } else {
        handleSkipFetch(query.anchorAddress);
      }
    });
  };

  const updateDates = async (anchorAddress) => {
    try {
      await Excel.run(async (context) => { // eslint-disable-line
        console.log('updateDates()');
        const query = state.queries.find((obj) => obj.anchorAddress === anchorAddress);

        function getFirstDate(year, month, frequency) {
          const monthZeroBased = month - 1;
          let date;
          if (frequency === 'M') {
            // Remember, JS counts months from 0-11
            date = endOfMonth(new Date(year, monthZeroBased, 1));
            // e.g. date = Sun Jan 31 2010 23:59:59 GMT+0200 (South Africa Standard Time)
          } else if (frequency === 'Q') {
            date = endOfQuarter(new Date(year, monthZeroBased, 1));
          } else if (frequency === 'A') {
            date = endOfYear(new Date(year, monthZeroBased, 1));
          }
          return date;
        }
        function getNextDate(date, frequency, i) {
          let nextDate;
          if (frequency === 'M') {
            nextDate = addMonths(date, i);
          } else if (frequency === 'Q') {
            nextDate = addQuarters(date, i);
          } else if (frequency === 'A') {
            nextDate = addYears(date, i);
          }
          return nextDate;
        }

        const firstDate =
          getFirstDate(query.startYear, query.startMonth, query.newFrequency);
        const dates = [...Array(query.newDataRowCount)].map((x, i) => {
          const d = getNextDate(firstDate, query.newFrequency, i);
          const oaDate = dateToOA(d);
          return [oaDate];
        });

        const sheet = context.workbook.worksheets.getActiveWorksheet();
        let datesRange = sheet.getRange(query.anchorAddress);
        datesRange = query.transposed ?
          datesRange.getOffsetRange(0, 1) : datesRange.getOffsetRange(1, 0);

        // Fill new dates
        datesRange = query.transposed ?
          datesRange.getResizedRange(0, query.newDataRowCount - 1) :
          datesRange.getResizedRange(query.newDataRowCount - 1, 0);
        await context.sync();

        datesRange.values = query.transposed ?
          dates[0].map((_, colIndex) => dates.map((row) => row[colIndex])) :
          dates; // [[…], […], […], …]
        if (enableFormatting) {
          datesRange.numberFormat = [['[$-409]yyyy/mm/dd']];
        }
        await context.sync(); // Wait to complete queued commands

        const anchorRange = sheet.getRange(query.anchorAddress);
        let completeQueryRange = query.transposed ?
          anchorRange.getResizedRange(query.tsCodes.length, -6) :
          anchorRange.getResizedRange(-6, query.tsCodes.length);
        completeQueryRange = query.transposed ?
          completeQueryRange.getResizedRange(0, query.newDataRowCount) :
          completeQueryRange.getResizedRange(query.newDataRowCount, 0);
        completeQueryRange.select();

        // FORMATTING
        if (enableFormatting) {
          // Highlight dates
          datesRange.format.fill.color = BLUE_LIGHT;
        }

        await context.sync(); // Wait to complete queued commands
      });
    } catch (error) {
      console.warn('updateDates() ERROR: ', error);
    }
  };

  const updateData = async (anchorAddress) => {
    try {
      await Excel.run(async (context) => { // eslint-disable-line
        console.log('updateData()');
        const query = state.queries.find((obj) => obj.anchorAddress === anchorAddress);

        dispatch({
          type: 'UPDATE_DATA',
          anchorAddress,
          status: 'BUSY',
        });

        const sheet = context.workbook.worksheets.getActiveWorksheet();
        const queryAnchorRange = sheet.getRange(query.tsCodesAddress);
        let dataRange = query.transposed ?
          queryAnchorRange.getOffsetRange(0, 1) :
          queryAnchorRange.getOffsetRange(1, 0);

        // We need to trim initial data points to match start year
        const {
          startIdx,
          newFrequency,
        } = query;

        // Size the data range correctly
        // Replace 'NA' with blanks so that formulas can still work nicely in Excel
        const tsArr = query.data[newFrequency].ts_li.map((ts) => {
          const vals = [
            ...query.padValues,
            ...ts.values.split(',').slice(startIdx),
          ];
          return vals.map((v) => {
            if (v === 'NA') {
              return '';
            }
            return v;
          });
        });

        dataRange = query.transposed ?
          dataRange.getResizedRange(0, tsArr[0].length - 1) :
          dataRange.getResizedRange(tsArr[0].length - 1, 0);
        await context.sync();

        // Wee need to transpose the 2D data array
        // https://stackoverflow.com/questions/17428587/transposing-a-2d-array-in-javascript
        const tsArrNew = query.transposed ?
          tsArr :
          tsArr[0].map((_, colIndex) => tsArr.map((row) => row[colIndex]));

        // eslint-disable-next-line prefer-destructuring
        dataRange.values = tsArrNew; // [[...], [...], [...], ...]
        if (enableFormatting) {
          dataRange.numberFormat = [['#,##0.00']];
        }
        // dataRange.format.autofitColumns(); // TODO: can we make columns a specific width? e.g. 12
        // dataRange.format.columnWidth = 70;
        await context.sync()
          .then(() => {
            dispatch({
              type: 'UPDATE_DATA',
              anchorAddress,
              status: 'DONE',
            });
          }); // Wait to complete queued commands
      });
    } catch (error) {
      console.warn(`-- updateData() ERROR: ${error}`);
    }
  };

  const updateMeta = async (anchorAddress) => {
    try {
      await Excel.run(async (context) => { // eslint-disable-line
        console.log('updateMeta()');
        const query = state.queries.find((obj) => obj.anchorAddress === anchorAddress);

        const sheet = context.workbook.worksheets.getActiveWorksheet();
        const anchorRange = sheet.getRange(query.anchorAddress);
        const metaRange = sheet.getRange(query.metaAddress);
        const metaAndCodesRange = query.transposed ?
          metaRange.getResizedRange(0, 1) :
          metaRange.getResizedRange(1, 0);
        const copyrightRange = query.transposed ?
          anchorRange.getOffsetRange(0, -6) :
          anchorRange.getOffsetRange(-6, 0);
        await context.sync();

        // FORMATTING
        if (enableFormatting) {
          // Highlight all meta cells
          // Highlight meta cells
          metaAndCodesRange.format.fill.color = BLUE_LIGHT;
          metaRange.format.font.color = '#000000';
          // Highlight copyright cell
          copyrightRange.format.font.color = BLUE;
          copyrightRange.format.font.bold = true;
          // Highlight anchor cell
          anchorRange.format.font.bold = true;
          if (query.frequency === 'M') {
            anchorRange.format.font.color = MONTHLY;
          } else if (query.frequency === 'Q') {
            anchorRange.format.font.color = QUARTERLY;
          } else if (query.frequency === 'A') {
            anchorRange.format.font.color = ANNUAL;
          }
        }

        const now = Date.now();
        let values = [
          [`Copyright ${format(now, 'yyyy')} Quantec EasyData, Untitled (${format(now, 'yyyy-MM-dd')} at ${format(now, 'HH:mm:ss')})`],
          ['Description'],
          ['Unit'],
          ['Source'],
          ['Notes'],
          ['frequency'],
          [`CODES:${query.frequency}`],
        ];

        const { access } = query.data[query.newFrequency];
        // TODO: Looks like availability is not working correctly
        // It gives 1 for a TS with data, 1 for a a TS without data, 1 for a TS with valid DS but
        // that does not exist and 0 for a TS with invalid DS.
        // const { availability } = query.data[query.newFrequency];

        query.data[query.newFrequency].ts_li.forEach((ts, i) => {
          const notes = ts.notes.map((x, j) => `${j + 1}. ${x}`).join(' ');
          const missing = ts.displayname.startsWith('Missing time series');
          values[0].push(ts.displayname);
          values[1].push(ts.description[0]);
          values[2].push(ts.unit);
          values[3].push(ts.source);
          if (access[i]) {
            values[4].push(notes);
          } else if (missing) {
            values[4].push('Missing or unavailable');
          } else {
            values[4].push('No access');
          }
          values[5].push(frequencyTitles[ts.frequency]);
          values[6].push(ts.code);
        });

        values = query.transposed ?
          values[0].map((_, colIndex) => values.map((row) => row[colIndex])) :
          values;
        metaAndCodesRange.values = values;
        await context.sync(); // Wait to complete queued commands

        // Display No access ts in RED
        let foundNoAccessRanges = sheet.findAllOrNullObject('No access', {
          completeMatch: true,
          matchCase: true,
        });
        let foundMissingRanges = sheet.findAllOrNullObject('Missing or unavailable', {
          completeMatch: true,
          matchCase: true,
        });
        foundNoAccessRanges = foundNoAccessRanges.getIntersection(metaRange);
        foundMissingRanges = foundMissingRanges.getIntersection(metaRange);
        foundNoAccessRanges.load('address');
        foundMissingRanges.load('address');
        await context.sync();

        // FORMATTING
        // ALWAYS Color NO ACCESS and MISSING
        const noAccessAddresses = foundNoAccessRanges.address ?
          [...foundNoAccessRanges.address.split(',')] : [];
        noAccessAddresses.forEach((addr) => {
          let range = sheet.getRange(addr);
          range = query.transposed ? range.getResizedRange(0, -4) : range.getResizedRange(-4, 0);
          range = query.transposed ? range.getResizedRange(0, 2) : range.getResizedRange(2, 0);
          range.format.fill.color = GREY_LIGHT;
          range.format.font.color = GREY;
        });
        const missingAddresses = foundMissingRanges.address ?
          [...foundMissingRanges.address.split(',')] : [];
        missingAddresses.forEach((addr) => {
          let range = sheet.getRange(addr);
          range = query.transposed ? range.getResizedRange(0, -4) : range.getResizedRange(-4, 0);
          range = query.transposed ? range.getResizedRange(0, 2) : range.getResizedRange(2, 0);
          range.format.fill.color = RED_LIGHT;
          range.format.font.color = RED;
        });
        await context.sync();

        dispatch({
          type: 'EDIT_LASTUPDATED',
          anchorAddress,
          newLastUpdated: now,
        });
      });
    } catch (error) {
      console.warn('updateMeta() ERROR: ', error);
    }
  };

  /*
   * A normal selection download has 2 aditional rows above that are not used when a query is
   * refreshed. These unused rows (or cells to be precise) need to be cleared to ensure neatness
   * after refresh.
   */
  const clearUnusedRows = async (anchorAddress) => {
    try {
      await Excel.run(async (context) => { // eslint-disable-line
        console.log('clearUnusedRows()');
        const query = state.queries.find((obj) => obj.anchorAddress === anchorAddress);

        const sheet = context.workbook.worksheets.getActiveWorksheet();
        let oldRange = sheet.getRange(query.anchorAddress);
        oldRange.load('rowIndex');
        await context.sync();

        const { transposed } = query;
        const { rowIndex } = oldRange;

        if (!transposed && rowIndex > 6) {
          oldRange = oldRange.getOffsetRange(-8, 0);
          oldRange = oldRange.getResizedRange(1, 0);
          oldRange.load('values');
          await context.sync();

          const oldCopy = oldRange.values[0][0];
          const oldDate = oldRange.values[1][0];

          if (oldCopy.startsWith('Copyright') && oldDate.startsWith('Download Date:')) {
            oldRange.clear();
          }
          await context.sync();
        } else if (transposed && rowIndex > 4) {
          oldRange = oldRange.getOffsetRange(-5, -3);
          oldRange = oldRange.getResizedRange(3, 0);
          oldRange.select();
          oldRange.load('values');
          await context.sync();

          const oldCopy = oldRange.values[0][0];
          const oldDate = oldRange.values[1][0];

          if (oldCopy.startsWith('Copyright') && oldDate.startsWith('Download Date:')) {
            oldRange.clear();
          }
          await context.sync();
        }
      });
    } catch (error) {
      console.warn('clearUnusedRows() ERROR: ', error);
    }
  };

  /*
   * We should not clear previous dates and data because it is impossible to accurately determine
   * how many rows of data there was previously.
  const clearPreviousDatesAndData = async (anchorAddress) => {
    try {
      await Excel.run(async (context) => { // eslint-disable-line
        console.log('clearPreviousDatesAndData()');
        const query = state.queries.find((obj) => obj.anchorAddress === anchorAddress);
        const tsCount = query.tsCodes.length;

        const sheet = context.workbook.worksheets.getActiveWorksheet();
        let oldDataRange = sheet.getRange(query.anchorAddress);
        oldDataRange = query.transposed ?
          oldDataRange.getOffsetRange(0, 1) :
          oldDataRange.getOffsetRange(1, 0);
        if (query.dataRowCount) {
          oldDataRange = query.transposed ?
            oldDataRange.getResizedRange(tsCount, query.dataRowCount - 1) :
            oldDataRange.getResizedRange(query.dataRowCount - 1, tsCount);
        }
        // Clear range values, format, fill, border, etc.
        oldDataRange.clear();
        await context.sync();
      });
    } catch (error) {
      console.warn('clearPreviousDatesAndData() ERROR: ', error);
    }
  };
   */

  useEffect(
    () => {
      if (state.queryUpdateQueue.length > 0) {
        const anchorAddress = state.queryUpdateQueue[0];
        clearUnusedRows(anchorAddress);
        // clearPreviousDatesAndData(anchorAddress);
        updateMeta(anchorAddress);
        updateDates(anchorAddress);
        updateData(anchorAddress);

        dispatch({
          type: 'REMOVE_FROMQUEUE',
        });
      }
    },
    [state.queryUpdateQueue],
  );

  const handleSetQueryFrequency = (anchorAddress, newFrequency) => {
    dispatch({
      type: 'EDIT_FREQUENCY',
      anchorAddress,
      newFrequency,
    });
  };

  const handleSetQueryStartYear = (anchorAddress, newStartYear) => {
    dispatch({
      type: 'EDIT_STARTYEAR',
      anchorAddress,
      newStartYear,
    });
  };

  return (
    <>
      <div className="bs4 flex-fill overflow-auto">
        <div className="bs4 d-flex">
          <button
            className={`bs4 btn btn-primary flex-fill shadow-none ${step2Status !== 'DONE' ? 'my-5' : 'btn-sm'}`}
            type="button"
            onClick={handleFindQueries}
            disabled={step1Status === 'BUSY' || step2Status === 'BUSY'}
          >
            <strong>
              Find data queries on worksheet
            </strong>
          </button>
        </div>

        {state.message &&
          <div className="bs5 flex-fill text-center text-secondary">
            {state.message}
          </div>}

        {step2Status === 'DONE' && queryCount > 0 &&
          <div className="bs5 flex-fill text-center m-2">
            <div>
              <button
                className="bs5 btn btn-primary shadow-none"
                type="button"
                onClick={handleFetchAllData}
              >
                <strong>
                  {`Refresh ${goodQueryCount}${faultyQueryCount ? `/${queryCount}` : ''} data quer${goodQueryCount === 1 ? 'y' : 'ies'}`}
                </strong>
              </button>
            </div>
            {faultyQueryCount > 0 &&
              <div className="bs4 small text-secondary my-1">
                {`${faultyQueryCount} problematic data quer${faultyQueryCount === 1 ? 'y' : 'ies'}.`}
              </div>}
          </div>}

        {step1Status === 'DONE' && step2Status === 'DONE' && queryCount > 0 &&
          <div className="bs5 flex-fill ">
            <div className="bs5 text-center mt-3">—</div>
            <QueryCards
              queries={state.queries}
              onFetchQueryData={handleFetchQueryData}
              onSetQueryFrequency={handleSetQueryFrequency}
              onSetQueryStartYear={handleSetQueryStartYear}
            />
          </div>}
      </div>

      <StatusBar
        // show={true}
        queryCount={queryCount}
        lastFetchDate={state.findQueries}
        step1Status={step1Status}
        step2Status={step2Status}
        step3Status={step3Status}
        step4Status={step4Status}
        step5Status={step5Status}
        // step1Arr={step1Arr}
        // step2Arr={step2Arr}
        // step3Arr={step3Arr}
        // step4Arr={step4Arr}
        // step5Arr={step5Arr}
      />
    </>
  );
};

FindQueryButton.propTypes = propTypes;

export default FindQueryButton;
