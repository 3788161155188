import React from 'react';
import PropTypes from 'prop-types';

import { format } from 'date-fns';

const propTypes = {
  show: PropTypes.bool,
  queryCount: PropTypes.number.isRequired,
  lastFetchDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  step1Status: PropTypes.oneOf(['TODO', 'BUSY', 'DONE']).isRequired,
  step2Status: PropTypes.oneOf(['TODO', 'BUSY', 'DONE']).isRequired,
  step3Status: PropTypes.oneOf(['TODO', 'BUSY', 'DONE']).isRequired,
  step4Status: PropTypes.oneOf(['TODO', 'BUSY', 'DONE']).isRequired,
  step5Status: PropTypes.oneOf(['TODO', 'BUSY', 'DONE']).isRequired,
  /*
  step1Arr: PropTypes.arrayOf(
    PropTypes.oneOf(['TODO', 'BUSY', 'DONE']),
  ).isRequired,
  step2Arr: PropTypes.arrayOf(
    PropTypes.oneOf(['TODO', 'BUSY', 'DONE']),
  ).isRequired,
  step3Arr: PropTypes.arrayOf(
    PropTypes.oneOf(['TODO', 'BUSY', 'DONE']),
  ).isRequired,
  step4Arr: PropTypes.arrayOf(
    PropTypes.oneOf(['TODO', 'BUSY', 'DONE']),
  ).isRequired,
  step5Arr: PropTypes.arrayOf(
    PropTypes.oneOf(['TODO', 'BUSY', 'DONE']),
  ).isRequired,
  */
};

const defaultProps = {
  show: true,
};

const StatusBar = (props) => {
  const {
    show,
    queryCount,
    lastFetchDate,
    step1Status,
    step2Status,
    step3Status,
    step4Status,
    step5Status,
    // step1Arr, step2Arr, step3Arr, step4Arr, step5Arr,
  } = props;

  /*
  console.log('StatusBar: ', show, step1Status, step2Status, step3Status, step4Status, step5Status);
  */

  return (
    <div
      className="bs5 border-top mt-2 d-flex align-items-center text-muted"
      style={{
        minHeight: '40px',
      }}
    >
      {show &&
        <>
          <span className="bs5 px-2">Ξ</span>

          {step1Status === 'TODO' &&
            <span>Ready.</span>}

          {step1Status === 'BUSY' &&
            <span className="bs4 text-success">Looking for queries in worksheet…</span>}

          {step2Status === 'BUSY' &&
            <span className="bs4 text-success">Verifying queries in worksheet…</span>}

          {step2Status === 'DONE' && step3Status === 'TODO' &&
            <span>
              {`Found ${queryCount} ${queryCount !== 1 ? 'queries' : 'query'}`}
              &nbsp;@&nbsp;
              {format(lastFetchDate, 'HH:mm:ii')}
            </span>}

          {step3Status === 'BUSY' &&
            <span className="bs4 text-success">Fetching data…</span>}

          {step4Status === 'BUSY' &&
            <span className="bs4 text-success">Storing data…</span>}

          {step5Status === 'BUSY' &&
            <span className="bs4 text-success">Updating data…</span>}

          {step5Status === 'DONE' &&
            <span>Data updated.</span>}
        </>}

      {/*
        show &&
          <>
            <p>
              <div>Step 1 - Find data queries:</div>
              {step1Arr.map((x) => <span className="bs4 mr-2">{x}</span>)}
            </p>
            <p>
              <div>Step 2 - Isolate data codes:</div>
              {step2Arr.map((x) => <span className="bs4 mr-2">{x}</span>)}
            </p>
            <p>
              <div>Step 3 - Fetch data:</div>
              {step3Arr.map((x) => <span className="bs4 mr-2">{x}</span>)}
            </p>
            <p>
              <div>Step 4 - Store data:</div>
              {step4Arr.map((x) => <span className="bs4 mr-2">{x}</span>)}
            </p>
            <p>
              <div>Step 5 - Update data:</div>
              {step5Arr.map((x) => <span className="bs4 mr-2">{x}</span>)}
            </p>
          </>
        */}
    </div>
  );
};

StatusBar.propTypes = propTypes;
StatusBar.defaultProps = defaultProps;

export default StatusBar;
